.infoBox {
    flex: 1;
    cursor: pointer;
}

.infoBox:not(:last-child){
    margin-right: 15px;
}

.infoBox__cases{
 color: #cc1034;
 font-weight: 600;
 font-size: 1.75rem;
 margin-bottom: 01.75rem;
}

infoBox__total{
    color: #6c757d;
    font-weight: 700 !important;
    font-size: 0.8rem !important;
    margin-top: 15px !important;
}