.liveCases{
    margin-top: 20px;
    overflow-y: scroll;
    height: 400px;
    color: #6a5d5d;
}

.liveCases tr{
    display: flex;
    justify-content: space-between;
}

.liveCases td{
    padding: 0.5rem;
}

.liveCases tr:nth-of-type(odd){
    background-color: #f3f2f8
}