*{
 margin: 0;
}

body{
  background-color: #f5f6fa;
}
.App{
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}

.app__header{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}

.app__left{
  flex: 0.8;
}

.app_stats{
  display: flex;
  justify-content: space-between;
}

@media (max-width:990px){
  .App{
    flex-direction: column;
  }
}